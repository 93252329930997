import { Button, Center, Text, useTheme } from "@chakra-ui/react";
import useAuth from "auth/useAuth";
import { getTokenData, handleOtesErrorFromAxiosError } from "auth/authHelpers";
import { AppPathHelper } from "AppPath";
import {
  ApplicantEntryTaskId,
  clearAllSavedTasks,
  clearSavedTask,
  resetTask,
  resetTasks,
} from "tasks/tasksApi";
import { unsetCommPrefs, unsetTos } from "auth/authApi";
import { useRef, useState } from "react";
import { dynamicActivate, languages as availableLanguages } from "App";
import { i18n } from "@lingui/core";
import SpinnerOverlay from "components/common/basic/SpinnerOverlay";
import taskHeadings from "tasks/taskHeadings";
import useOtesModal from "error/useOtesModal";
import { t } from "@lingui/macro";
import { ResponseCodes } from "tasks/apiConstants";
import { AxiosError } from "axios";

export default function Debug(): JSX.Element {
  const theme = useTheme();
  const [requesting, setRequesting] = useState<boolean>(false);
  const { orange, white } = theme.colors.brand;
  const { token } = useAuth();
  const location = window.location;
  const tokenData = getTokenData(token);
  const localStorageJson = JSON.stringify(localStorage, null, 3);
  const sessionStorageJson = JSON.stringify(sessionStorage, null, 3);
  const tokenDataJson = JSON.stringify(tokenData, null, 3);
  const clearResetTaskRef = useRef<HTMLSelectElement>(null);
  const { triggerOtesModal } = useOtesModal();

  const expirationDate = new Date(
    new Date().getTime() + tokenData.exp,
  ).toISOString();
  const expirationDays = Math.floor(tokenData.exp / 1000 / 60 / 60 / 24);

  const handleDebugActionError = (response: AxiosError) => {
    handleOtesErrorFromAxiosError(response);

    if (
      ResponseCodes.Error.Action.TASK_NOT_FOUND ===
      response?.response?.data?.code
    ) {
      triggerOtesModal({
        bodyText: t`Selected task is not present for this candidate, please select a different one.`,
      });
    } else {
      triggerOtesModal({
        bodyText: t`Something went wrong, please try again.`,
      });
    }

    setRequesting(false);
  };

  const clearSaveTaskOnClick = () => {
    const taskId = clearResetTaskRef.current?.value ?? "";
    if (taskId?.trim()) {
      setRequesting(true);
      clearSavedTask(token, taskId)
        .then(() => location.reload())
        .catch(handleDebugActionError);
    }
  };

  const clearAllSavedTasksOnClick = () => {
    setRequesting(true);
    clearAllSavedTasks(token)
      .then(() => location.reload())
      .catch(handleDebugActionError);
  };

  const resetTaskOnClick = () => {
    const taskId = clearResetTaskRef.current?.value ?? "";
    if (taskId?.trim()) {
      setRequesting(true);
      resetTask(token, taskId)
        .then(() => location.reload())
        .catch(handleDebugActionError);
    }
  };

  const resetTasksOnClick = () => {
    setRequesting(true);
    resetTasks(token)
      .then(() => location.reload())
      .catch(handleDebugActionError);
  };

  const unsetCommPrefOnClick = () => {
    setRequesting(true);
    unsetCommPrefs(token)
      .then(() => location.reload())
      .catch(handleDebugActionError);
  };

  const unsetTOSOnClick = () => {
    setRequesting(true);
    unsetTos(token)
      .then(() => location.reload())
      .catch(handleDebugActionError);
  };

  const handleLanguageChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    dynamicActivate(event.target.value);
  };

  const getAppEntryOptions = (): ApplicantEntryTaskId[] => {
    const appEntryOptions: ApplicantEntryTaskId[] = [];

    for (const key in taskHeadings) {
      appEntryOptions.push(key as ApplicantEntryTaskId);
    }

    return appEntryOptions;
  };

  if (requesting) {
    return <SpinnerOverlay text={"Performing debug action..."} />;
  } else {
    const currentLanguage = i18n.locale;
    const selectTarget = (event: any) => event.target.select();

    return (
      <>
        <table style={{ width: "100%", fontSize: "12px" }}>
          <tbody>
            <tr>
              <td>Link</td>
              <td>
                <input
                  onFocus={selectTarget}
                  style={{
                    border: "solid black 1px",
                    width: "100%",
                  }}
                  type="text"
                  value={`${location.protocol}//${location.host}/${AppPathHelper.TOKEN_PATTERN}${token}`}
                  readOnly
                />
              </td>
            </tr>
            <tr>
              <td>Token</td>
              <td>
                <input
                  onFocus={selectTarget}
                  style={{
                    border: "solid black 1px",
                    width: "100%",
                  }}
                  type="text"
                  value={token}
                  readOnly
                />
              </td>
            </tr>
            <tr>
              <td>App</td>
              <td>
                <input
                  onFocus={selectTarget}
                  style={{
                    border: "solid black 1px",
                    width: "100%",
                  }}
                  type="text"
                  value={tokenData.APPLICATIONID}
                  readOnly
                />
              </td>
            </tr>
            <tr>
              <td>Expiry</td>
              <td>
                <input
                  onFocus={selectTarget}
                  style={{
                    border: "solid black 1px",
                    width: "100%",
                  }}
                  type="text"
                  value={`${expirationDate} (${expirationDays} days)`}
                  readOnly
                />
              </td>
            </tr>
            <tr>
              <td>Comm Type</td>
              <td>
                <input
                  onFocus={selectTarget}
                  style={{
                    border: "solid black 1px",
                    width: "100%",
                  }}
                  type="text"
                  value={tokenData.comm_type}
                  readOnly
                />
              </td>
            </tr>
            <tr>
              <td>Verifications</td>
              <td>
                <input
                  onFocus={selectTarget}
                  style={{
                    border: "solid black 1px",
                    width: "100%",
                  }}
                  type="text"
                  value={tokenData.verifications}
                  readOnly
                />
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <Text
                  align={"center"}
                  width={"100%"}
                  fontWeight={"bold"}
                  borderTop={"double"}
                  my={3}
                  pt={3}>
                  Debugging Actions
                </Text>
              </td>
            </tr>
            <tr title="Enter in awp_applicant_entry.taskId(app-entry-personal-info) or awp_applicant_entry.display_name(Personal Information) then click Clear or Reset button.">
              <td>Reset App Entry Task</td>
              <td>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <select
                          ref={clearResetTaskRef}
                          style={{
                            fontSize: 12,
                            width: 110,
                            border: "solid black 1px",
                          }}>
                          {getAppEntryOptions().map((value, index) => {
                            return (
                              <option key={index} value={value as string}>
                                {taskHeadings[value].long}
                              </option>
                            );
                          })}
                        </select>
                      </td>
                      <td>
                        <Button
                          size={"xs"}
                          pl={"3px"}
                          pr={"3px"}
                          backgroundColor={orange}
                          color={"#ffffff"}
                          onClick={clearSaveTaskOnClick}>
                          Clear
                        </Button>
                      </td>
                      <td>
                        <Button
                          size={"xs"}
                          pl={"3px"}
                          pr={"3px"}
                          backgroundColor={orange}
                          color={white}
                          onClick={resetTaskOnClick}>
                          Reset
                        </Button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td width={"150px"}>Clear All Saved Data</td>
              <td>
                <Button
                  size={"xs"}
                  width={"100%"}
                  backgroundColor={orange}
                  color={white}
                  onClick={clearAllSavedTasksOnClick}
                  title={"Clear data from incomplete saved Tasks"}>
                  Clear All
                </Button>
              </td>
            </tr>
            <tr>
              <td>Reset All App Entry</td>
              <td>
                <Button
                  size={"xs"}
                  width={"100%"}
                  backgroundColor={orange}
                  color={white}
                  onClick={resetTasksOnClick}
                  title={"Clear data from all Tasks"}>
                  Reset All
                </Button>
              </td>
            </tr>
            <tr>
              <td>Unset Comm Pref</td>
              <td>
                <Button
                  size={"xs"}
                  width={"100%"}
                  backgroundColor={orange}
                  color={white}
                  onClick={unsetCommPrefOnClick}
                  title={"Re-trigger Communication Preference screen"}>
                  Unset
                </Button>
              </td>
            </tr>
            <tr>
              <td>Unset Terms of Service</td>
              <td>
                <Button
                  size={"xs"}
                  width={"100%"}
                  backgroundColor={orange}
                  color={white}
                  onClick={unsetTOSOnClick}
                  title={"Re-trigger Terms of Service screen"}>
                  Unset
                </Button>
              </td>
            </tr>
            <tr>
              <td>Change Language</td>
              <td>
                <select
                  onChange={handleLanguageChange}
                  style={{
                    border: "solid black 1px",
                  }}>
                  <option value={currentLanguage}>{currentLanguage}</option>
                  {Object.keys(availableLanguages).map((value, index) => {
                    return (
                      <option key={index} value={value}>
                        {value}
                      </option>
                    );
                  })}
                </select>
              </td>
            </tr>
          </tbody>
        </table>
        <Center mt={1}>JWT</Center>
        <textarea
          onFocus={selectTarget}
          readOnly
          style={{
            overflow: "auto",
            border: "solid black 1px",
            width: "100%",
            height: "100px",
            fontSize: "12px",
          }}
          value={`${tokenDataJson}`}
        />
        {localStorageJson !== "{}" && (
          <>
            <Center mt={1}>Local Storage</Center>
            <textarea
              onFocus={selectTarget}
              readOnly
              style={{
                overflow: "auto",
                border: "solid black 1px",
                width: "100%",
                height: "100px",
                fontSize: "12px",
              }}
              value={`${localStorageJson}`}
            />
          </>
        )}
        {sessionStorageJson !== "{}" && (
          <>
            <Center mt={1}>Session Storage</Center>
            <textarea
              onFocus={selectTarget}
              readOnly
              style={{
                overflow: "auto",
                border: "solid black 1px",
                width: "100%",
                height: "100px",
                fontSize: "12px",
              }}
              value={`${sessionStorageJson}`}
            />
          </>
        )}
      </>
    );
  }
}
